import { render, staticRenderFns } from "./AddProductDrugPrescription.vue?vue&type=template&id=4b57616b&scoped=true&"
import script from "./AddProductDrugPrescription.vue?vue&type=script&lang=js&"
export * from "./AddProductDrugPrescription.vue?vue&type=script&lang=js&"
import style0 from "./AddProductDrugPrescription.vue?vue&type=style&index=0&id=4b57616b&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b57616b",
  null
  
)

export default component.exports